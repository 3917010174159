<template>
    <div>
        <div>{{ item.day }}</div>
        <div class="title">{{ item.date }}</div>
    </div>
</template>

<script>
export default {
    name: 'ScheduleDayHead',
    props: {
        item: {
            type: Object,
            default: undefined
        }
    }
}
</script>

<style lang=scss>

</style>
